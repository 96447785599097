@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Urbanist", sans-serif !important;
}

/* 
scss
 */
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;
$font-extraBold: 800;
$font-black: 900;

$mainBg: #F5F6FA;
$primary-color: #131F43;
$icon-bgcolor: #F6F6F6;
$text-graycolor: #727272;
$cardIcon1-bg: #F3F4F8;
$cardIcon2-bg: #FAF4FB;
$cardIcon3-bg: #FAF7F3;
$cardIcon4-bg: #F5F9FC;
$cardIcon2-color: #AC44BB;
$cardIcon3-color: #D69A5F;
$cardIcon4-color: #176CA5;
$green-color: #38A169;
$light-green: #DBFFEC;
$darkGreen-color: #207a4a;
$placeholder-color: #666666;
$border-color: #EAEAEA;
$ligh-black: #434343;
$border-gray : #D4D9EE;
$light-gray : #999999;
$fade-gray : #F4F4F4;
$text-gray : #939393;
$heading : #0B0D0F;
$red-color : #F94747;
$blackColor : #000;
$Red : #EE3F3F;
$lightRed : #DD3C3C;
$tabLightBdr : #F2F2F2;
$checkbx_bg : #C6C6C6;
$menu_icon : #CBCBCB;


/* 
Body Theme Color
 */
$lightthemecolor: #f3f6f9;
$darkthemecolor: #151521;

$white-color: #fff;
$black-color: #1d1d1d;
$fullBlack-color: #1d1d1d;
$lightthemetext: #60677e;

/* 
Dark Theme Colors
*/
$primarydark-color: #1e1e2d;

/* 
Button Theme
*/
$primary-btncolor: #131F43;
$secondary-btncolor: rgba(37, 105, 109, 0.864);

$tableheadlightbg: #e9eaf0;


/* 
Max Dashboard scss
*/
$dashboardwidth: calc(100% - 320px);
$dashboardposition: absolute;
$dashboardleft: 290px;
$dashboardpadding: 30px;

/* 
Min Dashboard scss
*/
$dashwidth: calc(100% - 50px);
$dashleft: 50px;

/* 
Max Dashboard scss
*/

.dashboard {
  width: $dashboardwidth;
  position: relative;
  left: $dashboardleft;
  padding: $dashboardpadding;
  border-radius: 8px;
  min-height: auto !important;
  background-color: $mainBg;
  height: calc(100vh - 160px);
  overflow-y: auto;
  bottom: 0 !important;
}

.dashboardContain {
  width: calc(100% - 320px);
  background-Color: $mainBg;
  border-radius: 8px;
}

.top_header .top_header_inner {
  background-color: transparent;
  height: auto;
  overflow-y: unset;
}

.password_show p {
  gap: 5px;
  display: flex;
}

.password_show p span {
  gap: 5px;
  display: flex;
}

.password_show .fa-eye-slash:before {
  margin-top: -7px;
  display: flex;
}

/* 
Min Dashboard scss
*/

.cont2 {
  width: $dashwidth;
  left: $dashleft;
  position: relative;
  padding: $dashboardpadding;
  border-radius: 8px;
  background: $mainBg;
  height: calc(100vh - 160px);
  overflow-y: auto;
  min-height: auto;
  /* 
background-Color: $dashboardbackgroundColor;
  padding-bottom: 8%;
*/
}

.subLink svg {
  position: relative;
  top: -2px;
  left: -7px;
}



.footer {
  height: 70px;
}

body {
  background-color: $white-color;
}

/* 
THEME CHANGE
*/
.bodythemelight {
  background-color: $white-color;
  padding-top: 90px;
}

.bodythemedark {
  background-color: $darkthemecolor;
  padding-top: 90px;
}

/* 
Header Navbar Theme
*/
.headerthemelight {
  background-color: $white-color;
}

.headerthemedark {
  background-color: $darkthemecolor;
}

.top_header {
  min-height: 90px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
}

/* 
LEFT MENU THEME CHANGE
*/
.menuthemelight {
  background-color: $white-color;
}

.menuthemedark {
  background-color: $darkthemecolor;
}

/* 
LEFT MENU LINKS THEME CHANGE
*/
.menulinklight span {
  color: $placeholder-color !important;
}

.menulinklight a {
  color: $menu_icon !important;
}

.menulinklight a svg {
  fill: $menu_icon !important;
}

.menulinklight:hover a svg {
  fill: $white-color !important;
}

.menulinklight:hover span,
.menulinklight:hover a {
  color: $white-color !important;
}

.menulinkdarkSelected {
  background-color: $white-color;
  color: $primary-color;
}

.menulinklightSelected a svg {
  fill: $white-color !important;
}

.menuthemelight .menulinklightSelected {
  /*background-color: $lightthemecolor;*/
  color: $white-color !important;
  background-color: $primary-color;
}

.menulinkdark {
  color: $lightthemetext !important;
}

.menulinklight:hover {
  color: $white-color !important;
  background-color: $primary-color;
}

.menulinkdark:hover {
  /* background-color: $primarydark-color; */
  color: $white-color !important;
}

/* 
BODY HEADERS
*/
.headinglight {
  color: $lightthemetext;
}

.headingdark {
  color: $lightthemetext;
}

/* 
SEARCH BOX THEME COLOR
*/
.searchboxlight {
  background-color: $white-color;
  border: none;
}
.searchboxlight::placeholder {
  font-weight: $font-medium;
}

.searchboxdark {
  background-color: $primarydark-color;
  color: $lightthemetext;
}

.searchboxdark::placeholder {
  color: $lightthemetext;
}

/* 
CUSTOM TABLE THEME COLOR
*/
.tableheadlight {
  background-color: $primary-color;
  border-radius: 5px 5px 0px 0px;
}

.tableheadlight th {
  color: $white-color;
  font-size: 14px;
  font-weight: $font-semiBold;
}

thead .tableheadlight th:first-child {
  border-radius: 5px 0 0 0px;
}

thead .tableheadlight th:last-child {
  border-radius: 0 5px 0px 0;
}

th:first-child {
  border-radius: 0px;
}

td:last-child {
  border-radius: 0px;
}

table.table.table-borderless {
  border-collapse: separate !important;
  border-spacing: 0 0px !important;
}

.tableheaddark {
  background-color: $primarydark-color;
  color: $lightthemetext;
}

.table-borderless tr:nth-child(even) {
  background-color: #F9FAFB;
}

.table-borderless .left-section {
  padding-top: 4px;
}

.tablebodylight td {
  padding: 0.5rem 1rem !important;
  line-height: 30px;
  font-size: 14px;
  font-weight: $font-medium;
  color: $primary-color;
  vertical-align: middle;
}


.tablebodylight {
  background-color: $white-color;

  &:hover {
    background-color: #ddecf9;
    cursor: pointer;
  }
}

.tablebodydark {
  background-color: $primarydark-color;
  color: $lightthemetext;

  &:hover {
    background-color: #252530;
    cursor: pointer;
  }
}

/* 
PAGINATION THEME COLOR
*/
.paginationlight {
  color: $lightthemetext !important;
  font-size: 13px !important;
}

.paginationdark {
  color: $lightthemetext !important;
  font-size: 13px !important;
}

/* 
SETTING FORM THEME COLOR
*/
.formthemelight {
  background-color: $white-color;
  color: $black-color !important;
  box-shadow: 0px 10px 35px 0px rgb(56 71 109 / 8%);
}

.formthemedark {
  background-color: $primarydark-color;
  color: $lightthemetext !important;
  font-size: 14px;

  ::placeholder {
    color: $lightthemetext;
  }
}


/* 
VIEW FORM DETAILS BOX THEME COLOR
*/
.viewformlight {
  background-color: $lightthemecolor;
}

.viewformdark {
  background-color: $darkthemecolor;
}

.permissionboxlight {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.permissionboxdark {
  background-color: #151521;
  border: none;
}

.errormsgclr {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* 
Graph theme
*/
.graphclrlight {
  background-color: $white-color;
}

.graphclrdark {
  background-color: $primarydark-color;
}


/* 
Counter theme
*/
#card1bglight {
  /* background: linear-gradient(90deg, rgba(181, 219, 177, 1) 13%, rgba(125, 178, 144, 1) 100%); */
  background-color: $white-color;
}

#card1bgdark {
  background: #392f28;
}

#card2bglight {
  /* background: linear-gradient(90deg, rgba(243, 198, 165, 1) 0%, rgba(248, 163, 168, 1) 100%); */
  background-color: $white-color;
}

#card2bgdark {
  background: #1c3238;
}

#card3bglight {
  /* background: linear-gradient(90deg, rgba(213, 149, 211, 1) 0%, rgba(174, 132, 192, 1) 100%); */
  background-color: $white-color;
}

#card3bgdark {
  background: #3a2434;
}

#card4bglight {
  /* background: linear-gradient(90deg, rgba(140, 212, 214, 1) 0%, rgba(150, 202, 247, 1) 100%); */
  background-color: $white-color;
}

#card4bgdark {
  background: #2f264f;
}

#card1bgdark a,
#card1bgdark p,
#card2bgdark a,
#card2bgdark p,
#card3bgdark a,
#card3bgdark p,
#card4bgdark a,
#card4bgdark p {
  color: $white-color;
}

/* 
Action button theme
*/
.actionbtnlight {
  color: #46475bde;
}

.actionbtndark {
  color: $lightthemetext;
}


/* 
profile dropdowntheme
*/
.profiledropdownlight {
  background-color: #fff;
}

.profiledropdowndark {
  background-color: #151521;
}

/* 
Tab
*/
.settingslinks {
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  position: relative;
}

.settingsgeneral {
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  position: relative;
  color: $white-color !important;
  background-color: $primary-btncolor !important;
}

.settingslinks:hover {
  color: $white-color;
  background-color: $primary-btncolor;
}

.settingsgeneral:hover {
  color: $white-color;
  background-color: $primary-btncolor;
}

/* 
Tab
*/

.inner_card {
  margin-top: 15px;
  padding: 30px 30px 0px 30px;
  border-radius: 5px;
  min-height: calc(100vh - 360px);
  background-color: $white-color;
}

.inner_card_withoutminheight {
  margin-top: 15px;
  padding: 30px 30px 0px 30px;
  border-radius: 5px;
  background-color: $white-color;
}

.videoCard {
  border-radius: 5px;
  border: solid 1px $border-color;
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
}

.video_section {
  border-radius: 5px 5px 0px 0px;
  background-color: #ccc;
  overflow: hidden;
}

.video_section img {
  object-fit: cover;
  width: 100%;
}

.video_container {
  padding: 20px 20px 26px 20px;
}

.video_time {
  display: flex;
  align-items: center;
}

.video_time img {
  margin-right: 5px;
}

.video_time p {
  font-size: 14px;
  color: $ligh-black;
  line-height: 20px;
  margin: 0px;
}

.video_container h1 {
  font-size: 18px;
  font-weight: $font-bold;
  line-height: 20px;
  margin: 10px 0px 0px 0px;
}

.editBtn {
  position: relative;
}

.editBtn svg {
  fill: $primary-color;
  position: relative;
  top: -3px;
  left: -5px;
}

.editBtn svg:hover {
  pointer-events: none;
}

.editBtn:hover svg,
.editBtn:active svg,
.editBtn:focus svg {
  fill: $white-color;
}

.details_card {
  padding: 25px 30px;
  border-radius: 5px;
  background-color: $white-color;
}

.details_card_head h1 {
  font-size: 22px;
  font-weight: $font-bold;
  color: $primary-color;
  margin-bottom: 0px;
}

.details_card_head {
  padding-bottom: 25px;
  position: relative;
}

.details_card_head::after {
  content: '';
  width: 100%;
  height: 1px;
  background: url(../../assets/border-line.svg);
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
  bottom: 0;
}

.card_numbers {
  display: flex;
  padding: 25px 0px;
}

.card_numbers span {
  font-size: 13px;
  font-weight: $font-semiBold;
  margin-bottom: 7px;
  display: block;
  color: $text-graycolor;
}

.card_numbers p {
  font-size: 16px;
  font-weight: $font-semiBold;
  color: $primary-color;
  margin-bottom: 0px;
}

.card_numbers.card_des {
  display: block;
  position: relative;
  padding: 27px 0px 0px 0px;
}

.card_numbers.card_des::before {
  content: '';
  width: 100%;
  height: 1px;
  background: url(../../assets/border-line.svg);
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
  top: 0;
}

.saveBtn {
  min-width: 120px;
  background-color: $primary-color;
  color: $white-color;
  border: none;
  padding: 0px 27px;
  font-size: 16px;
  font-weight: $font-semiBold;
}

.saveBtn:hover {
  background-color: $primary-color;
  color: $white-color;
  cursor: pointer;
}

.saveBtn:disabled {
  pointer-events: all;
  cursor: pointer;
}

.saveBtn svg {
  position: relative;
  left: -10px;
  top: -1px;
}

.nml_form .form_fieldset label {
  display: block;
  font-size: 14px;
  font-weight: $font-semiBold;
  color: $text-graycolor;
  margin-bottom: 12px;
}

.nml_form .form_fieldset {
  margin-bottom: 28px;
}

.nml_form .form_fieldset input {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  color: $black-color;
  font-size: 14px;
  background-color: $mainBg;
  min-height: 50px;
  border: none;
}

.nml_form .form_fieldset textarea {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  color: $black-color;
  font-size: 14px;
  background-color: $mainBg;
  min-height: 120px;
  border: none;
}

.nml_form .form_fieldset textarea:focus {
  outline: none;
}

.resetbtn {
  font-size: 16px;
  font-weight: $font-semiBold;
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px $primary-color;
  color: $primary-color;
  min-width: 113px;
}

.resetbtn:hover,
.resetbtn:active,
.resetbtn:focus {
  background-color: $primary-color;
  color: $white-color;
}

.addBtn {
  min-width: 140px;
  height: 44px;
  color: $white-color;
  background-color: $green-color;
  border: none;
  font-size: 16px;
  font-weight: $font-semiBold;
}

.addBtn:hover {
  background-color: #217247;
}

body .form_head .btn-primary {
  display: inline-flex !important;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.search_contain .btn-primary {
  display: inline-flex !important;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.addBtn svg {
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.addBtn:hover {
  color: $white-color;
  background-color: $green-color;
  border: none;
}

.cust_addBtn.addBtn svg {
  top: 2px;
}

.deleteBtn {
  min-width: 130px;
  height: 50px;
  background-color: $red-color;
  color: $white-color;
  font-size: 16px;
  font-weight: $font-semiBold;
  border: none;
}

.deleteBtn:active,
.deleteBtn:focus {
  background-color: $red-color !important;
  color: $white-color !important;
  box-shadow: none !important;
}

.deleteBtn svg {
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.deleteBtn:hover,
.deleteBtn:active,
.deleteBtn:focus {
  background-color: $red-color;
  color: $white-color;
  border: none;
}

.main_head {
  font-size: 22px;
  font-weight: $font-bold;
  color: $heading;
  align-items: center;
  display: flex;
}

.articleBox_top {
  justify-content: space-between;
}

.articleBox_top,
.articleBox_date {
  display: flex;
  align-items: center;
}

.articleBox_date img {
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.form_radio {
  display: flex;
}

.checkBox [type="radio"]:checked,
.checkBox [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.checkBox [type="radio"]:checked+label,
.checkBox [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: $text-graycolor;
}

.checkBox [type="radio"]:checked+label:before,
.checkBox [type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid $blackColor;
  border-radius: 100%;
  background: #fff;
}

.checkBox [type="radio"]:not(:checked)+label:before {
  border: 2px solid $text-graycolor;
}

.checkBox [type="radio"]:checked+label {
  color: $blackColor;
}

.checkBox [type="radio"]:checked+label:after,
.checkBox [type="radio"]:not(:checked)+label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: $blackColor;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.checkBox [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.checkBox [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.user_data {
  margin-bottom: 22px;
}

.user_data span {
  display: block;
  font-size: 13px;
  font-weight: $font-semiBold;
  color: $text-graycolor;
  margin-bottom: 5px;
}

.user_data p {
  font-size: 16px;
  font-weight: $font-semiBold;
  color: $primary-color;
  margin-bottom: 0px;
}

.user_pswd .fa-eye-slash {
  position: relative;
  right: -7px;
  top: -3px;
}

.user_pswd .fa-eye {
  position: relative;
  right: -7px;
  top: 0px;
}

.approve_btn {
  min-width: 215px;
  height: 50px;
  border-radius: 5px;
  background-color: $primary-color;
  color: $white-color;
  border: none;
}

.approve_btn svg {
  margin-right: 10px;
}

.approve_btn:hover {
  background-color: $primary-color;
  color: $white-color;
  border: none;
}

.exercise_footer {
  position: relative;
  padding-top: 30px;
}

.exercise_footer::after {
  content: '';
  width: 100%;
  height: 1px;
  background: url(../../assets/border-line.svg);
  background-repeat: repeat-x;
  position: absolute;
  left: 0;
  top: 0;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

span.published {
  background-color: $light-green;
  color: $green-color;
}

.text_right {
  text-align: right;
}

.expired {
  font-size: 12px;
  font-weight: $font-medium;
  color: $Red ;
}

.saveBdrBtn {
  min-height: 50px;
  border: solid 1px $primary-color;
  color: $primary-color;
  background-color: transparent;
}

.saveBdrBtn:hover svg {
  fill: $white-color;
}

.saveBdrBtn:active,
.saveBdrBtn:focus {
  color: $white-color;
  background-color: $primary-color;
}

.saveBdrBtn:active svg,
.saveBdrBtn:focus svg {
  fill: $white-color;
}

.exer_modal_contain .inner_card {
  padding: 0px;
  margin-bottom: 0px;
  box-shadow: none;
}

.exer_modal_contain .video_sectionBox {
  height: auto;
}

.confirmBtn {
  background-color: $primary-color;
}

.confirmBtn:hover,
.confirmBtn:active,
.confirmBtn:focus {
  background-color: $primary-color;
}

.confirmBtn svg {
  top: 0;
}

.ql-toolbar.ql-snow {
  padding: 8px;
  border-radius: 5px 5px 0px 0px;
  background-color: #F5F6FA;
  border: none !important;
  border: solid 1px #e1e1e1 !important;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow,
.ql-editor {
  border-radius: 0px 0px 5px 5px;
  height: 350px;
  background-color: #F5F6FA;
  border: none;
}

.ql-editor {
  border: solid 1px #e1e1e1;
  border-top: none;
}

.ck.ck-toolbar,
.ck.ck-editor__main>.ck-editor__editable {
  background-color: #F5F6FA !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  height: 350px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: 0px 0px 5px 5px !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *) {
  border-radius: 5px 5px 0px 0px !important;
}

.table_top_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_top_inner_head {
  font-size: 22px;
  font-weight: 700;
  color: #0B0D0F;
  margin-bottom: 20px;
}

.table_top_inner .search_contain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table_top_inner .search_contain button {
  margin-bottom: 0px;
}



.Toastify__toast-container--top-right {
  top: 97px !important;
  left: auto;
  min-width: 425px;
  right: 43px !important;
}

.Toastify__toast--success {
  min-width: 424px !important;
  /* min-height: 90px !important; */
  font-family: "Urbanist", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  background-color: #38A169 !important;
  color: #fff !important;
  padding: 20px !important;
  border-radius: 5px !important;
}

.Toastify__toast--success svg {
  fill: #fff !important;
}


.Toastify__toast--success .Toastify__toast-body {
  font-family: "Urbanist", sans-serif !important;
  font-size: 18px;
  font-weight: 600;
  padding: 0px !important;
  margin: 0px !important;
  align-items: center;
}

.Toastify__toast--success .Toastify__toast-icon {
  margin-inline-end: 7px !important;
  width: 32px !important;
  height: 32px;
}



.Toastify__close-button--light {
  border: solid 2px #fff !important;
  border-radius: 100px;
  width: 21px;
  height: 21px;
  opacity: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Toastify__close-button>svg {
  height: 15px !important;
  width: 16px !important;
}

.css-141b1tp-control,
.css-1wa22fn-control {
  background-color: #f5f6fa !important;
  height: 50px !important;
  min-height: 50px !important;
  padding: 0px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.css-1d8n9bt {
  height: 50px;
  padding-top: 0px !important;
}

.css-ackcql {
  height: 50px;
  margin: 0px !important;
  padding: 0px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  color: #1C274C !important;
  padding: 8px 20px 8px 8px !important;
}

.css-319lph-ValueContainer {
  height: 50px;
  padding: 0px 18px !important;
  background: #F5F6FA;
  border-radius: 5px 0px 0px 5px;
}

.css-6j8wv5-Input {
  height: 50px;
  padding: 0;
  margin: 0px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  background-color: #F5F6FA;
  border-radius: 0px 5px 5px 0px;
}

.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}

.dropdown-item:active {
  background-color: #e9ecef !important;
}

.test {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.table-responsive {
  margin-top: 15px;
}

.react-date-picker__inputGroup__input {

  height: auto !important;
  min-height: auto !important;
}
.css-26l3qy-menu
{
  max-width: 300px !important;
  width: 300px !important;
  min-width: 300px !important;
}

.css-kbjsfi-control,
.css-pyyg2w-control
{
  max-width: 300px !important;
}
.new_loader {
   /* position: fixed;
    width: 100%;
    height: 100%;
    z-index: 13333;
     background: #fffffff2; */
}

.table-responsive {
  position: relative;
}

.new_loader.new_loader_modal {
  top: 10% !important;
}

.new_loader {
    position: absolute;
    height: 50%;
    top: 50%;
    z-index: 13333;
    left: 0;
    right: 0;
    /* background: #fffffff2; */
}

/* HTML: <div class="loader"></div> */
.loader_box {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.loader_box::before,
.loader_box::after {    
  content:"";
  grid-area: 1/1;
  --c:no-repeat radial-gradient(farthest-side,#131F43 92%,#0000);
  background: 
    var(--c) 50%  0, 
    var(--c) 50%  100%, 
    var(--c) 100% 50%, 
    var(--c) 0    50%;
  background-size: 8px 8px;
  animation: l12 1s infinite;
}
.loader_box::before {
  margin: 2px;
  filter: hue-rotate(45deg);
  background-size: 4px 4px;
  animation-timing-function: linear
}





.loader_box_sml {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.loader_box_sml::before,
.loader_box_sml::after {    
  content:"";
  grid-area: 1/1;
  --c:no-repeat radial-gradient(farthest-side,#131F43 92%,#0000);
  background: 
    var(--c) 50%  0, 
    var(--c) 50%  100%, 
    var(--c) 100% 50%, 
    var(--c) 0    50%;
  background-size: 8px 8px;
  animation: l12 1s infinite;
}
.loader_box_sml::before {
  margin: 2px;
  filter: hue-rotate(45deg);
  background-size: 4px 4px;
  animation-timing-function: linear
}

@keyframes l12 { 
  100%{transform: rotate(.5turn)}
}

@media only screen and (max-width: 1200px) {
  .tableheadlight th {
    min-width: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .dashboard {
    width: calc(100% - 50px) !important;
    left: 50px !important;
    padding: 20px 10px !important;
  }
}

@media only screen and (max-width: 998px) {
  #dashboardcont2 {
    height: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .sectionright {
    float: unset !important;
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow,
  .ql-editor {
    height: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .cont2 {
    width: $dashwidth !important;
    left: $dashleft !important;
    position: relative !important;
    padding: 20px 10px !important;
  }
  .bodythemelight,
  .bodythemedark {
    padding-top: 90px;
  }
  .css-26l3qy-menu
{
  max-width: 100%  !important;
  width: 100%  !important;
  min-width: 100% !important;
}
.css-kbjsfi-control
{
  max-width:100% !important;
  min-width:100% !important;
}
.css-pyyg2w-control
{
  max-width:100% !important;
  min-width:100% !important;
}
.table_top_inner {
  display: grid;
}
.table_top_inner .search_contain {
  display: grid;
}
}

.cust_dpdwn .css-i0neym-control {
  background-color: #fff;
}

.cust_dpdwn .css-2fuipi-control {
  background-color: #fff;
}

.cust_dpdwn.sub_dpdwn .css-i0neym-control, 
.cust_dpdwn.sub_dpdwn .css-2fuipi-control {
  background-color: #F5F6FA;
  padding: 0px;
}

.cust_dpdwn.sub_dpdwn .css-i0neym-control *, 
.cust_dpdwn.sub_dpdwn .css-2fuipi-control *{
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
}

.date_field .form-control {
  background-color: #F5F6FA;
  border: none;
}

.react-date-picker span.react-date-picker__inputGroup__leadingZero {
  padding: 0px 7px 0px 2px;
  margin: 0px;
  right: -7px;
  z-index: 99999;
  position: relative;
}

.react-date-picker {
  width: 100%;
  background: #f5f6fa;
  border-radius: 5px;
  min-height: 50px;
  align-items: center;
  padding: 0px 10px;
}

.flr_dpdwn .css-1d8n9bt, 
.flr_dpdwn .css-ackcql, 
.search_contain .css-1d8n9bt, 
.search_contain .css-ackcql 
{
  height: auto;
}

.text-center {
  text-align: center;
}
.verify_logo {
  width: 400px;
  margin: auto;
  margin-top: 30px;
}

.dashboard::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.dashboard::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.dashboard::-webkit-scrollbar-thumb:hover{
  border: 0;
  cursor: pointer;
}

.dashboard::-webkit-scrollbar-track {
  background: transparent;
}