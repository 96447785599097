@import '/src/styles/Variables/Variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: "Urbanist", sans-serif !important;
}


/* .App {
  text-align: center;
} */
/* :root {
  --black: #000;
  --white: #f5f5f5;
}

body{
  background: var(--background-color);
  color: var(--text-color);
}

body.light{
  --background-color: var(--white);
  --text-color:var(--black);
}
body.dark{
  --background-color: var(--black);
  --text-color:var(--white);
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mr-1 {
  margin-right: 10px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.mb-20 {
  margin-bottom: 20px;
}


.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 10%;
  right: 10%;
  width: 80%;
  color: #babfc7;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #050420;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.cookie-banner p {
  margin-bottom: 0px;
  font-size: 13px;
}

.close {
  height: 30px;
  width: 130px;
  font-size: 11px;
  background-color: #32c167;
  border: none;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
}

.react-confirm-alert-overlay {
  background-color: #0e1726ba !important;
}

.react-confirm-alert-body > h1 {
  color: #0e1726e0 !important;
  text-align: center !important;
  font-size: 24px !important;
}

.react-confirm-alert-body {
  background: #fff !important;
  color: #0e1726e0 !important;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: bold;
}

.react-confirm-alert-button-group {
  justify-content: center !important;
}
.react-confirm-alert-button-group > button {
  background: #39979d !important;
  color: #fff !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

/*Ag grid Table*/

.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  background: transparent !important;
  text-overflow: initial !important;
}

.ag-theme-alpine .ag-header {
  border-bottom: none !important;
  margin-bottom: 0px;
  border-radius: 5px 5px 0px 0px !important;
  min-height: 45px !important;
  border: none !important;
  text-overflow: initial !important;
  background: #131F43 !important;
}
.ag-theme-alpine .ag-row {
  height: 63px !important;
  border-radius: 0px !important;
  padding-top: 0px !important;
  font-size: 14px;
  color: #131F43;
  font-weight: 500;
}

.ag-header-cell-text {
  text-overflow: initial !important;
}

.ag-cell-label-container {
  height: auto !important;
}

.ag-theme-alpine .ag-row-odd {
  background-color: #F9FAFB !important;
}

.ag-theme-alpine, .ag-root.ag-layout-normal {
  font-family: "Urbanist", sans-serif !important;  
}



/* .ag-header-cell-label{
  text-overflow: initial !important;
  overflow: initial !important;
}

.ag-cell-value{
  text-overflow: initial !important;
  overflow: initial !important;
} */

/* @media only screen and (max-width: 400px) {
  .ag-theme-alpine .ag-header-cell{
    width: 91px !important;
  }
} */

/* .ag-root.ag-layout-normal{
  overflow: scroll;
} */

/*Ag grid Pagination*/

.ag-theme-alpine .ag-paging-panel {
  border-top: none !important;
  color: #60677e !important;
  font-size: 12px !important;
}
.ag-theme-alpine .ag-icon {
  color: #60677e !important;
}

.ag-theme-alpine .ag-paging-panel > * {
  margin: 0px -2px !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  width: auto !important; /*table data cell width*/
  display: flex;
  align-items: center;
}

/*date range*/

.rdrCalendarWrapper {
  position: absolute;
  z-index: 1;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
  color: #39979d !important;
}

.rdrDateDisplayItemActive {
  border-color: #39979d !important;
}

.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__divider {
  color: #60677e !important;
}

.react-date-picker__button svg {
  stroke: #60677e;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
  border-color: none !important;
  outline: none !important;
}

/* .ag-theme-alpine .ag-row-selected{
  background: initial !important;
} */

/* .ag-theme-alpine .ag-row-hover{
  background: #fff !important;
} */

.ag-center-cols-viewport {
  height: calc(100% + 12px) !important;
}
.ag-center-cols-container {
  width: 100% !important;
}

.css-qc6sy-singleValue {
  color: #60677e !important;
}

.popup-arrow {
  display: none !important;
}

input[type='checkbox' i] {
  accent-color: #39979d !important;
  vertical-align: middle;
}

input:focus {
  outline: none !important;
}

/* Add Green Button  */
.addBtn{
  padding: 10px;
  min-width: 140px;
  border: none;
  background-color: #38A169 !important;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight:600;
}

.addBtn:hover{
  background-color: #207a4a;
}

.add_icon{
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  top: 2px;
}


/* Search  */
.searchbar{
  min-width: 240px;
  min-height: 44px;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 5px 5px 40px;
  width: 100%;
}
.table_drpdwn{
  min-width: 240px;
  border-radius: 5px;
  background-color: transparent !important;
  padding: 0px;
  width: 100%;
}

.table_drpdwn .css-1s2u09g-control{
  min-height: 44px;
  border: none;
}

.search_contain {
  position: relative;
}

.search_contain img {
  position: absolute;
  top: 14px;
  left: 15px;
}

::placeholder{
  font-size: 14px; 
  font-weight: 600;
  color: #666666;
}

.table_top {
  display: flex;
  justify-content: flex-end;
}

/* Date range */
.date_range_main p {
  font-size: 14px;
  font-weight: 600;
  color: #727272;
  margin-bottom: 10px;
}
.date_range_contain {
  position: relative;
}
.date_btn {
  width: 50px;
  height: 44px;
  position: absolute;
  left: 0;
  padding: 0px;
  border: 0px;
  top: 0;
  background: transparent;
}
.date_btn img {
  width: 18px;
  position: relative;
  top: -1px;
}
.date_input {
  min-height: 44px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  min-width: 250px;
  padding-left: 50px;
  display: inline-flex;
  align-items: center;
}

/* Dropdown */
.drpdwn .drpdwn_btn {
  padding: 10px;
  min-width: 110px;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  color: #888888;
  font-size: 16px;
  font-weight: 600;
}
.drpdwn .drpdwn_btn img {
  margin-left: 10px;
}
.drpdwn .drpdwn_btn:hover {
  background-color: #131F43;
}
.drpdwn .drpdwn_btn:focus {
  background-color: #131F43;
  box-shadow: none;
}

/* Form Head */
.form_head {
  display: flex;
  justify-content: space-between;
}

/* Form Container */
.form_container {
  padding: 30px 18px;
}
.form_container label {
  font-size: 14px;
  font-weight: 600;
  color: #727272;
  margin-bottom: 12px;
}
.form_container .form-field label {
  margin-bottom: 0px;
  position: relative;
  bottom: -2px;
}
.form_container .textarea_contain .form-field label {
  margin-bottom: 12px;
}
.form_container input {
  background-color: #F5F6FA;
  border-radius: 5px;
  min-height: 50px;
  border: none;
  padding: 10px;
  width: 100%;
  color: #1d1d1d;
  font-size: 14px;
}
.form_container textarea {
  background-color: #F5F6FA;
  border-radius: 5px;
  border: none;
  padding: 10px;
  width: 100%;
  color: #1d1d1d;
  font-size: 14px;
}
.form_container input[type='checkbox'] {
  background-color: #F5F6FA;
  border-radius: 5px;
  min-height: unset;
}
/* .form_container input::placeholder,
.form_container textarea::placeholder {
  color: transparent;
}  */
.form_container .form-group {
  margin-bottom: 23px;
}
.form_container .selectDrp {
  border: none !important;
  outline: none !important;
}
.form_container .selectDrp .css-b62m3t-container {
  position: relative;
}
.form_container .selectDrp .css-141b1tp-control:hover, 
.form_container .selectDrp .css-1wa22fn-control:hover 
{
  cursor: pointer;
}
.form_container .selectDrp .css-b62m3t-container::after {
  content: url(../src/assets/arrow_down.svg);
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 13px;
}
.form_container .selectDrp .css-141b1tp-control,
.form_container .selectDrp .css-1wa22fn-control {
  background-color: #F5F6FA;
  border-radius: 5px;
  min-height: 50px;
  border: none;
}
.form_container .selectDrp input {
  min-height: unset;
}
.form_container .selectDrp .css-1okebmr-indicatorSeparator {
  display: none;
}
.form_container .selectDrp .css-tlfecz-indicatorContainer  svg {
  display: none;
}
.form_container .selectDrp .css-1gtu0rj-indicatorContainer svg {
  display: none;
}
.form_container .dateRange input {
  padding: 0px 10px;
  background-color: transparent;
}
.form_field_contain input {
  width: unset;
}
.addRole_container {
  padding: 25px 20px;
}
.addRole_container .add_input label {
  font-size: 14px;
  font-weight: 600;
  color: #727272;
  margin-bottom: 12px;
}
.addRole_container .add_input input {
  background-color: #F5F6FA;
  border-radius: 5px;
  min-height: 50px;
  border: none;
  padding: 10px;
  width: 100%;
  color: #1d1d1d;
  font-size: 14px;
}
.addRole_container .add_input input::placeholder {
  color: transparent;
}

/* Submit Button */
.sub_btn input{
  width: unset;
  min-width: 140px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  background-color: #131F43;
  color: #fff;
  border: none;
  box-shadow: none;
  padding-left: 40px;
}
.sub_btn input:hover{
  background-color: #142350;
}
.sub_btn {
  position: relative;
}
.sub_btn::after {
  content: url('../src/assets/submit.svg');
  display: block;
  position: absolute;
  left: 30px;
  top: -1px;
  pointer-events: none;
}
.updateBtn input {
  padding: 0px;
}
.updateBtn::after {
  content: "";
}

/* Reset Button */
.resetBtn {
  min-width: 140px;
  min-height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  background-color: transparent;
  border: solid 1px #131F43;
  color: #131F43;
  box-shadow: none;
}
.resetBtn:hover {
  background-color: #131F43;
  color: #fff;
}

/* Back Button */
.backBtn {
  min-width: 100px;
  min-height: 50px;
  background-color: #fff;
  border-radius: 5px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.backBtn:hover {
  cursor: pointer;
}

/* Tab */
.tabNav {
  background-color: transparent !important;
  box-shadow: none !important;
}
.tabNav li.nav-item {
  margin-right: 8px;
}
.tabNav li a {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  color: #767676;
}

.profile_conatiner {
  padding-top: 0px !important;
}

.edit_btn {
  min-width: 140px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}

.subLink {
  line-height: 30px;
  width: 100%;
  display: block;
  /* padding-left: 15px; */

  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1.2rem;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 15px;
}

.profile_view_container {
  padding: 25px 20px;
}
.profile_view_container span {
  font-size: 14px;
  font-weight: 600;
  color: #727272;
  margin-bottom: 12px;
  display: inline-block;
}

.tabNav.exercises_tab li {
  margin-bottom: 15px;
}

.exer_modal_contain .modal-body {
  padding: 20px 20px 0px 20px;
}

.exer_modal_contain .exercises_tab li.nav-item {
  margin-right: 10px;
}

.exer_modal_contain .exercises_tab li a {
  border: solid 1px #F2F2F2;
  letter-spacing: normal;
}

.exer_modal_contain .exercises_tab li:last-child {
  margin-right: 0px;
}

.exer_modal_contain .modal-title {
  font-size: 22px;
  font-weight: bold;
  color: #131F43;
}

.exer_modal_contain .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 10px 30px;
  border: none;
}

.exer_modal_contain .modal-header .save_btn {
  min-width: 140px;
  height: 50px;
}

.exer_modal_contain .modal-dialog {
  padding-top: 30px;
}

.exer_modal_contain .modal-header .btn-close {
  position: absolute;
  right: 0;
  top: -47px;
  background: url(../src/assets/popup_close.svg);
  background-repeat: no-repeat;
  opacity: 1;
}

.mr-2 {
  margin-right: 20px;
}

.delPopContainer .modal-body {
  padding: 25px;
  text-align: center;
}

.delPopContainer .delet_icon {
  margin-bottom: 11px;
}

.delPopContainer p {
  font-size: 14px;
  color: #131F43;
  font-weight: bold;
}



@media only screen and (min-width: 991px) {
  .mob-back {
  display: none;
  }
  .web-back {
  display: flex;
  }
  }
  @media only screen and (max-width: 992px) {
  .mob-back {
  display: flex;
  }
  .web-back {
  
  display: none;
  }
  .form_wrapper {
  display: flex;
  flex-flow: column;
  }
  .form_wrapper :nth-child(2) {
  order: 3;
  }
  .form_head {
  margin-top: 15px;
  }
  .form_wrapper :nth-child(3) {
  order: 2;
  }
  .date_range_main .date_input {
  width: 100%;
  min-width: auto;
  }
  .dropTopMain.dropTopMainWrap {
  margin-top: 30px !important;
  }
  .dropTopMain .dropdown, .dropTopMain .btn {
  width: 100%;
  }
  .form_container {
  margin-bottom: 10px !important;
  }
  }
  @media only screen and (max-width: 767px) {
  .dropTopMain.dropTopMainWrap {
  margin-top: 0px !important;
  }
  .dropTopMain .drpdwn {
  margin-bottom: 15px;
  }
  .table_top .search_contain.mr-1{
  margin-right: 0px !important;
  }
  }
  
  @media only screen and (max-width: 600px) {
    .searchbar {
      min-width: unset;
    }
    .table_top {
      display: block;
    }
    .exer_modal_contain .modal-header .btn-close {
      top: -22px;
    }
  }


@media (min-width: 576px) {
  .exer_modal_contain .modal-dialog {
      max-width: 1055px;
  }
  .delPopContainer .modal-dialog {
      max-width: 350px;
  }
}

ol{
  list-style-position: inside;
}

ul{
  list-style-position: inside;
}

.ql-align-center{
  text-align: center;
}

.ql-align-right{
  text-align: right;
}